import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-power-list"></a><h2>Psionic Power List
    </h2>
    <a id="psionic-disciplines"></a><h3>PSION DISCIPLINES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="egoist-psychometabolism-discipline-powers"></a><h5>EGOIST (PSYCHOMETABOLISM) DISCIPLINE POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>Level
          </th>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td><a href="psionicPowersQtoW.html#thicken-skin" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thicken Skin<sup>A</sup>:</a></td>
          <td>Gain +1 enhancement bonus to your AC for 10 min./level.
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td><a href="psionicPowersAtoC.html#animal-affinity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Animal AffinityA:</a></td>
          <td>Gain +4 enhancement to one ability.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersAtoC.html#chameleon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Chameleon:</a></td>
          <td>Gain +10 enhancement bonus on Hide checks.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#empathic-transfer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Transfer<sup>A</sup>:</a></td>
          <td>Transfer another&rsquo;s wounds to yourself.</td>
        </tr>
        <tr className="odd-row">
          <td>3
          </td>
          <td><a href="psionicPowersDtoF.html#ectoplasmic-form" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Form:</a></td>
          <td>You gain benefits of being insubstantial and can fly
slowly.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#hustle" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hustle:</a></td>
          <td>Instantly gain a move action. </td>
        </tr>
        <tr className="odd-row">
          <td>4
          </td>
          <td><a href="psionicPowersGtoP.html#metamorphosis" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metamorphosis:</a></td>
          <td>Assume shape of creature or object.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#psychic-vampire" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Vampire:</a></td>
          <td>Touch attack drains 2 power points/level from foe.</td>
        </tr>
        <tr className="odd-row">
          <td>5
          </td>
          <td><a href="psionicPowersGtoP.html#psionic-revivify" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Revivify<sup>A X</sup>:</a></td>
          <td>Return the dead to life before the psyche leaves the
corpse. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#psychofeedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychofeedback:</a></td>
          <td>Boost Strength, Dexterity, or Constitution at the
expense of one or more other scores.
          </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#restore-extremity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Restore Extremity:</a></td>
          <td>Return a lost digit, limb, or other appendage to
subject.</td>
        </tr>
        <tr>
          <td>6
          </td>
          <td><a href="psionicPowersQtoW.html#restoration-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Restoration, Psionic:</a></td>
          <td>Restores level and ability score drains.</td>
        </tr>
        <tr className="odd-row">
          <td>7
          </td>
          <td><a href="psionicPowersDtoF.html#fission" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fission:</a></td>
          <td>You briefly duplicate yourself.</td>
        </tr>
        <tr>
          <td>8
          </td>
          <td><a href="psionicPowersDtoF.html#fusion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fusion<sup>X</sup>:</a></td>
          <td>You combine your abilities and form with another.</td>
        </tr>
        <tr className="odd-row">
          <td>9
          </td>
          <td><a href="psionicPowersGtoP.html#metamorphosis-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metamorphosis, Greater<sup>X</sup>:</a></td>
          <td>Assume shape of any nonunique creature or object each
round.</td>
        </tr>
      </tbody>
    </table>
    <a id="kineticist-psychokinesis-discipline-powers"></a><h5>KINETICIST (PSYCHOKINESIS) DISCIPLINE POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>Level
          </th>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td><a href="psionicPowersAtoC.html#control-object" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Object:</a> </td>
          <td>Telekinetically animate a small object.
          </td>
        </tr>
        <tr>
          <td>2
          </td>
          <td><a href="psionicPowersAtoC.html#control-air" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Air<sup>A</sup>:</a> </td>
          <td>You have control over wind speed and direction. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#energy-missile" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Missile<sup>A</sup>:</a> </td>
          <td>Deal 3d6 energy damage to up to five subjects. </td>
        </tr>
        <tr>
          <td>3
          </td>
          <td><a href="psionicPowersDtoF.html#energy-cone" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Cone<sup>A</sup>:</a> </td>
          <td>Deal 5d6 energy damage in 60-ft. cone. </td>
        </tr>
        <tr className="odd-row">
          <td>4
          </td>
          <td><a href="psionicPowersAtoC.html#control-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control BodyA:</a> </td>
          <td>Take rudimentary control of your foe&rsquo;s limbs. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#energy-ball" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Ball<sup>A</sup>:</a> </td>
          <td>Deal 7d6 energy damage in 20-ft. radius. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#inertial-barrier" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inertial Barrier:</a> </td>
          <td>Gain DR 5/-. </td>
        </tr>
        <tr>
          <td>5
          </td>
          <td><a href="psionicPowersDtoF.html#energy-current" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Current<sup>A</sup>:</a> </td>
          <td>Deal 9d6 damage to one foe and half to another foe as
long as you concentrate.
          </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#fiery-discorporation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fiery Discorporation<sup>A</sup>:</a>
          </td>
          <td>Cheat death by discorporating into nearby fire for one
day. </td>
        </tr>
        <tr>
          <td>6
          </td>
          <td><a href="psionicPowersDtoF.html#dispelling-buffer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dispelling Buffer:</a> </td>
          <td>Subject is buffered from one dispel psionics effect. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#null-psionic-field" style={{
              "color": "rgb(87, 158, 182)"
            }}>Null Psionic Field:</a> </td>
          <td>Create a field where psionic power does not function. </td>
        </tr>
        <tr>
          <td>7
          </td>
          <td><a href="psionicPowersQtoW.html#reddopsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Reddopsi:</a> </td>
          <td>Powers targeting you rebound on manifester. </td>
        </tr>
        <tr className="odd-row">
          <td>8
          </td>
          <td><a href="psionicPowersQtoW.html#telekinetic-sphere-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Sphere, Psionic:</a> </td>
          <td>Mobile force globe encapsulates creature and moves it. </td>
        </tr>
        <tr>
          <td>9
          </td>
          <td><a href="psionicPowersQtoW.html#tornado-blast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tornado Blast<sup>A</sup>:</a> </td>
          <td>Vortex of air subjects your foes to 17d6 damage and
moves them. </td>
        </tr>
      </tbody>
    </table>
    <a id="nomad-psychoportation-discipline-powers"></a><h5>NOMAD (PSYCHOPORTATION) DISCIPLINE POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>Level
          </th>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td><a href="psionicPowersAtoC.html#burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Burst:</a> </td>
          <td>Gain +10 ft. to speed this round.
          </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#detect-teleportation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Teleportation<sup>A</sup>:</a>
          </td>
          <td>Know when teleportation powers are used in close range.
          </td>
        </tr>
        <tr className="odd-row">
          <td>2
          </td>
          <td><a href="psionicPowersDtoF.html#dimension-swap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Swap<sup>A</sup>:</a> </td>
          <td>You and ally or two allies switch positions. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#levitate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Levitate, Psionic:</a> </td>
          <td>Subject moves up and down, forward and back, at your
direction. </td>
        </tr>
        <tr className="odd-row">
          <td>3
          </td>
          <td><a href="psionicPowersAtoC.html#astral-caravan" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Caravan<sup>A</sup>:</a> </td>
          <td>You lead astral traveler-enabled group to a planar
destination. </td>
        </tr>
        <tr>
          <td>4
          </td>
          <td><a href="psionicPowersDtoF.html#dimensional-anchor-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimensional Anchor, Psionic:</a> </td>
          <td>Bars extra dimensional movement. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#dismissal-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dismissal, Psionic:</a> </td>
          <td>Forces a creature to return to its native plane. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#fly-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fly, Psionic:</a> </td>
          <td>You fly at a speed of 60 ft. </td>
        </tr>
        <tr className="odd-row">
          <td>5
          </td>
          <td><a href="psionicPowersAtoC.html#baleful-teleport" style={{
              "color": "rgb(87, 158, 182)"
            }}>Baleful Teleport<sup>A</sup>:</a> </td>
          <td>Destructive teleport deals 9d6 damage. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#teleport-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleport, Psionic:</a> </td>
          <td>Instantly transports you as far as 100 miles/level. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#teleport-trigger" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleport Trigger:</a> </td>
          <td>Predetermined event triggers teleport. </td>
        </tr>
        <tr>
          <td>6
          </td>
          <td><a href="psionicPowersAtoC.html#banishment-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Banishment, Psionic<sup>A</sup>:</a>
          </td>
          <td>Banishes extraplanar creatures. </td>
        </tr>
        <tr className="odd-row">
          <td>7
          </td>
          <td><a href="psionicPowersDtoF.html#dream-travel" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dream Travel<sup>A</sup>:</a> </td>
          <td>Travel to other places through dreams. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#ethereal-jaunt-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ethereal Jaunt, Psionic:</a> </td>
          <td>Become ethereal for 1 round/level. </td>
        </tr>
        <tr className="odd-row">
          <td>8
          </td>
          <td><a href="psionicPowersQtoW.html#time-hop-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Time Hop, Mass<sup>A</sup>:</a> </td>
          <td>Willing subjects hop forward in time. </td>
        </tr>
        <tr>
          <td>9
          </td>
          <td><a href="psionicPowersQtoW.html#teleportation-circle-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleportation Circle, Psionic:</a> </td>
          <td>Circle teleports any creatures inside to designated
spot. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#time-regression" style={{
              "color": "rgb(87, 158, 182)"
            }}>Time Regression<sup>X</sup>:</a> </td>
          <td>Relive the last round. </td>
        </tr>
      </tbody>
    </table>
    <a id="seer-clairsentience-discipline-powers"></a><h5>SEER (CLAIRSENTIENCE) DISCIPLINE POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>Level
          </th>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td><a href="psionicPowersDtoF.html#destiny-dissonance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Destiny Dessonance:</a> </td>
          <td>Your dissonant touch sickens a foe.
          </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#precognition" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition:</a> </td>
          <td>Gain +2 insight bonus to one roll. </td>
        </tr>
        <tr className="odd-row">
          <td>2
          </td>
          <td><a href="psionicPowersAtoC.html#clairvoyant-sense" style={{
              "color": "rgb(87, 158, 182)"
            }}>Clairvoyant Sense:</a> </td>
          <td>See and hear a distant location. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#object-reading" style={{
              "color": "rgb(87, 158, 182)"
            }}>Object Reading<sup>A</sup>:</a> </td>
          <td>Learn details about an object&rsquo;s previous owner. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#sensitivity-to-psychic-impressions" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sensitivity to Psychic Impressions:</a>
          </td>
          <td>You can find out about an area&rsquo;s past. </td>
        </tr>
        <tr>
          <td>3
          </td>
          <td><a href="psionicPowersDtoF.html#escape-detection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Escape Detection:</a> </td>
          <td>You become difficult to detect with clairsentience
powers. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#fate-link" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fate Link<sup>A</sup>:</a> </td>
          <td>You link the fates of two targets. </td>
        </tr>
        <tr>
          <td>4
          </td>
          <td><a href="psionicPowersAtoC.html#anchored-navigation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Anchored Navigation<sup>A</sup>:</a>
          </td>
          <td>Establish a mishap-free teleport beacon. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#remote-viewing" style={{
              "color": "rgb(87, 158, 182)"
            }}>Remote Viewing<sup>X</sup>:</a> </td>
          <td>See, hear, and potentially interact with subjects at a
distance. </td>
        </tr>
        <tr>
          <td>5
          </td>
          <td><a href="psionicPowersAtoC.html#clairtangent-hand" style={{
              "color": "rgb(87, 158, 182)"
            }}>Clairtangent Hand<sup>A</sup>:</a> </td>
          <td>Emulate far hand at a distance. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#second-chance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Second Chance:</a> </td>
          <td>Gain a reroll. </td>
        </tr>
        <tr>
          <td>6
          </td>
          <td><a href="psionicPowersGtoP.html#precognition-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Greater:</a> </td>
          <td>Gain +4 insight bonus to one roll. </td>
        </tr>
        <tr className="odd-row">
          <td>7
          </td>
          <td><a href="psionicPowersDtoF.html#fate-of-one" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fate of One:</a> </td>
          <td>Reroll any roll you just failed. </td>
        </tr>
        <tr>
          <td>8
          </td>
          <td><a href="psionicPowersGtoP.html#hypercognition" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hypercognition:</a> </td>
          <td>You can deduce almost anything. </td>
        </tr>
        <tr className="odd-row">
          <td>9
          </td>
          <td><a href="psionicPowersGtoP.html#metafaculty" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metafaculty<sup>X</sup>:</a> </td>
          <td>You learn details about any one creature. </td>
        </tr>
      </tbody>
    </table>
    <a id="shaper-metacreativity-discipline-powers"></a><h5>SHAPER (METACREATIVITY) DISCIPLINE POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>Level
          </th>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td><a href="psionicPowersAtoC.html#astral-construct" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Construct<sup>A</sup>:</a> </td>
          <td>Creates astral construct to fight for you.
          </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#minor-creation-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Minor Creation, Psionic:</a> </td>
          <td>Creates one cloth or wood object. </td>
        </tr>
        <tr className="odd-row">
          <td>2
          </td>
          <td><a href="psionicPowersGtoP.html#psionic-repair-damage" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Repair Damage:</a> </td>
          <td>Repairs construct of 3d8 hit points +1 hp/level. </td>
        </tr>
        <tr>
          <td>3
          </td>
          <td><a href="psionicPowersAtoC.html#concealing-amorpha-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concealing Amorpha,&nbsp; Greater:</a>
          </td>
          <td>Quasi-real membrane grants you total concealment. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#ectoplasmic-cocoon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Cocoon<sup>A</sup>:</a> </td>
          <td>You encapsulate a foe so it can&rsquo;t move. </td>
        </tr>
        <tr>
          <td>4
          </td>
          <td><a href="psionicPowersDtoF.html#fabricate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fabricate, Psionic:</a> </td>
          <td>Transforms raw goods to finished items. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#quintessence" style={{
              "color": "rgb(87, 158, 182)"
            }}>Quintessence:</a> </td>
          <td>You collapse a bit of time into a physical substance. </td>
        </tr>
        <tr>
          <td>5
          </td>
          <td><a href="psionicPowersGtoP.html#hail-of-crystals" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hail of Crystals<sup>A</sup>:</a> </td>
          <td>A crystal explodes in an area, dealing 9d4 slashing
damage. </td>
        </tr>
        <tr className="odd-row">
          <td>6
          </td>
          <td><a href="psionicPowersAtoC.html#crystallize" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crystallize:</a> </td>
          <td>Turn subject permanently to crystal. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#fabricate-greater-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fabricate, Greater Psionic:</a> </td>
          <td>Transforms a lot of raw goods to finished items. </td>
        </tr>
        <tr className="odd-row">
          <td>7
          </td>
          <td><a href="psionicPowersDtoF.html#ectoplasmic-cocoon-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Cocoon, Mass:</a> </td>
          <td>You encapsulate all foes in a 20-ft. radius. </td>
        </tr>
        <tr>
          <td>8
          </td>
          <td><a href="psionicPowersAtoC.html#astral-seed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Seed:</a> </td>
          <td>You plant the seed of your rebirth from the Astral
Plane. </td>
        </tr>
        <tr className="odd-row">
          <td>9
          </td>
          <td><a href="psionicPowersGtoP.html#genesis" style={{
              "color": "rgb(87, 158, 182)"
            }}>Genesis<sup>X</sup>:</a> </td>
          <td>You instigate a new demiplane on the Astral Plane. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#true-creation" style={{
              "color": "rgb(87, 158, 182)"
            }}>True Creation<sup>X</sup>:</a> </td>
          <td>As psionic major creation, except items are completely
real. </td>
        </tr>
      </tbody>
    </table>
    <a id="telepath-telepathy-discipline-powers"></a><h5>TELEPATH (TELEPATHY) DISCIPLINE POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>Level
          </th>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td><a href="psionicPowersAtoC.html#charm-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Charm, PsionicA:</a> </td>
          <td>Makes one person your friend.
          </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#mindlink" style={{
              "color": "rgb(87, 158, 182)"
            }}>MindlinkA:</a> </td>
          <td>You forge a limited mental bond with another creature. </td>
        </tr>
        <tr className="odd-row">
          <td>2
          </td>
          <td><a href="psionicPowersAtoC.html#aversion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aversion<sup>A</sup>:</a> </td>
          <td>Subject has aversion you specify. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersAtoC.html#brain-lock" style={{
              "color": "rgb(87, 158, 182)"
            }}>Brain Lock<sup>A</sup>:</a> </td>
          <td>Subject cannot move or take any mental actions. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#read-thoughts" style={{
              "color": "rgb(87, 158, 182)"
            }}>Read Thougths:</a> </td>
          <td>Detect surface thoughts of creatures in range. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#suggestion-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Suggestion, Psionic:</a> </td>
          <td>Compels subject to follow stated course of action. </td>
        </tr>
        <tr className="odd-row">
          <td>3
          </td>
          <td><a href="psionicPowersAtoC.html#crisis-of-breath" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crisis of Breath<sup>A</sup>:</a> </td>
          <td>Disrupt subject&rsquo;s breathing. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#empathic-transfer-hostile" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Transfer, Hostile<sup>A</sup>:</a>
          </td>
          <td>Your touch transfers your hurt to another. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersDtoF.html#false-sensory-input" style={{
              "color": "rgb(87, 158, 182)"
            }}>False Sensory Input<sup>A</sup>:</a>
          </td>
          <td>Subject sees what isn&rsquo;t there. </td>
        </tr>
        <tr>
          <td>4
          </td>
          <td><a href="psionicPowersDtoF.html#dominate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dominate, PsionicA:</a> </td>
          <td>Control target telepathically. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#mindlink-thieving" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mindlink, Thieving<sup>A</sup>:</a> </td>
          <td>Borrow knowledge of a subject&rsquo;s power. </td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#modify-memory-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Modify Memory, Psionic:</a> </td>
          <td>Changes 5 minutes of subject&rsquo;s memories. </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersQtoW.html#schism" style={{
              "color": "rgb(87, 158, 182)"
            }}>Schism:</a> </td>
          <td>Your partitioned mind can manifest lower level powers. </td>
        </tr>
        <tr>
          <td>5
          </td>
          <td><a href="psionicPowersGtoP.html#metaconcert" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaconcert<sup>A</sup>:</a> </td>
          <td>Mental concert of two or more increases the total power
of the participants.
          </td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a href="psionicPowersGtoP.html#mind-probe" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Probe:</a> </td>
          <td>You discover the subject&rsquo;s secret thoughts. </td>
        </tr>
        <tr>
          <td>6
          </td>
          <td><a href="psionicPowersGtoP.html#mind-switch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Switch<sup>A X</sup>:</a> </td>
          <td>You switch minds with another. </td>
        </tr>
        <tr className="odd-row">
          <td>7
          </td>
          <td><a href="psionicPowersAtoC.html#crisis-of-life" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crisis of Life<sup>A</sup>:</a> </td>
          <td>Stop subject&rsquo;s heart. </td>
        </tr>
        <tr>
          <td>8
          </td>
          <td><a href="psionicPowersGtoP.html#mind-seed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Seed<sup>X</sup>:</a> </td>
          <td>Subject slowly becomes you. </td>
        </tr>
        <tr className="odd-row">
          <td>9
          </td>
          <td><a href="psionicPowersGtoP.html#mind-switch-true" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Switch, True<sup>X</sup>:</a> </td>
          <td>A permanent brain swap. </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#psychic-chirurgery" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Chirurgery<sup>X</sup>:</a></td>
          <td style={{
            "verticalAlign": "top"
          }}>You repair psychic damage
or impart knowledge of new powers.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      